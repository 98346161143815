export const form = {
  brand: "#6184d8",
  brandAccent: "#6185d8b2",
  brandButtonText: "white",
  defaultButtonBackground: "white",
  defaultButtonBackgroundHover: "#eaeaea",
  defaultButtonBorder: "lightgray",
  defaultButtonText: "#333333",
  dividerBackground: "#e5e5e5",
  inputBorder: "#e5e5e5",
  inputBorderHover: "#4d4d4d",
  inputBorderFocus: "#4d4d4d",
  inputText: "#333333",
  inputLabelText: "#333333",
  inputPlaceholder: "#4d4d4d",
  messageText: "gray",
  messageTextDanger: "red",
  anchorTextColor: "gray",
  anchorTextHoverColor: "darkgray",
};
